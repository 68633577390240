const FastSpeedtest = require("fast-speedtest-api");
import { candidateSystemCheck, tokenForUserMobileLogin } from "../apiFunction";
import axios from "axios";
import { mapGetters } from "vuex";
import {onSave}  from "../mixins/LoginFromCand";
import LoginModals from "../components/modals/LoginToastModals"

let internet = {
  data() {
    return {
      AllowedMobAndLap: true,
      property: "value",
      angleValue: 45,
      randomInterval: null,
      downloadFetched: false,
      uploadFetched: false,
      bothFetched: false,
      downloadSpeed: 0,
      speed: 0,
      uploadSpeed: 0,
      checkupfinish: false,
      analyzeUpload: null,
      analyzedownload: null,
    };
  },
  components:{
    LoginModals
  },
  props: {
    value: Number, // Progress value between 0 and 100
  },
  mounted() {
    localStorage.clear();
    this.getdata();
    this.randomInterval = setInterval(() => {
      this.randomizeAngle();
    }, 500);
    this.checkDownloadSpeed();
    this.checkUploadSpeed();
    setTimeout(() => {
      this.bothFetched = true;
      this.checkupfinish = true;
      clearInterval(this.randomInterval);
    }, 4000);
  },

  computed: {
    ...mapGetters("sectionVUEX", {
      uuid: "getUUID",
      systemCheckStatus: "getSystemCheck",
      internetCheckStatus: "getInternetCheck",
    }),
    speedToDegree() {
      return Math.round(this.downloadSpeed * 1.8) - 45;
    },
    progressBarStyle() {
      return {
        background: `conic-gradient(
            #4c78ce ${10 * 3.6}deg,
            #fff ${10 * 3.6}deg
          )`,
      };
    },
    isNetSpeed() {
      return this.downloadSpeed >= 8 && this.uploadSpeed >= 8;
    },
    rotation() {
      return this.value * 3.6; // Convert percentage to degrees (1% = 3.6deg)
    },
  },
  
  methods: {
    async getdata() {
      const res = await tokenForUserMobileLogin();
      if (res.status == 200) {
        localStorage.setItem("vue_token", res.data.token);
        localStorage.setItem("uuid", res.data.userid);
        this.getMobileVerification();
      }
    },
    async getMobileVerification() {
      try {
        let res = await candidateSystemCheck(`?uuid=${this.$route.query.uuid}`);
        console.log(res.data.data.Detail);
        this.AllowedMobAndLap = true;
      } catch (error) {
        this.AllowedMobAndLap = false;
      }
    },
    async checkDownloadSpeed() {
      try {
        const startTime = Date.now();
        console.log(performance.now(), Date.now());
        const response = await axios.get(
          "https://browsertimer.simplifycv.com/measure",
          {
            responseType: "blob",
          }
        );
        const endTime = Date.now();
        const downloadTime = (endTime - startTime) / 1000;
        const sizeInBytes = Number(response.headers["content-length"]);
        const sizeInMb = (sizeInBytes * 8) / (1024 * 1024);
        const downloadSpeed = sizeInMb / downloadTime;
        this.downloadFetched = true;
        this.downloadSpeed =
          Math.floor(downloadSpeed) > 100 ? 100 : Math.floor(downloadSpeed);
      } catch (error) {
        console.log(error);
      }
    },
    next() {
      localStorage.clear();
      if (this.systemCheckStatus) {
        this.$router.push({
          name: "SystemCheck",
          query: {
            uuid: this.$route.query.uuid,
          },
        });
      } else {
        //uncomment when query comes
        if(this.checkAccessTokenCookie()&&this.$route.query.from=='candidate'){
      onSave(this.checkAccessTokenCookie(),this.$route.query.uuid, this.$router);
        }
      //     if(this.checkAccessTokenCookie()){
      // onSave(this.checkAccessTokenCookie(),this.$route.query.uuid, this.$router);
      //   }
        else{
              this.$router.push({
                name: "StudentLogin",
                query: {
                  uuid: this.$route.query.uuid,
                },
              });
            }
      }
    },
    checkAccessTokenCookie() {
      const cookies = document.cookie.split('; ');
      for (let i = 0; i < cookies.length; i++) {
        const cookiePair = cookies[i].split('=');
        if (cookiePair[0] === 'accessToken') {
          return cookiePair[1]
        }
      }
      return false;
    },
    resetAllValues() {
      this.downloadFetched = false;
      this.uploadFetched = false;
      this.downloadFetched = false;
      this.bothFetched = false;
      this.downloadSpeed = 0;
      this.uploadSpeed = 0;
      this.randomInterval = setInterval(() => {
        this.randomizeAngle();
      }, 2000);
      setTimeout(() => {
        this.bothFetched = true;
        this.checkupfinish = true;
        clearInterval(this.randomInterval);
      }, 4000);
      this.checkDownloadSpeed();
      this.checkUploadSpeed();
    },
    randomizeAngle() {
      this.angleValue = Math.floor(Math.random() * 201) - 100;
    },
    checkNetSpeed() {
      let speedtest = new FastSpeedtest({
        token: "YXNkZmFzZGxmbnNkYWZoYXNkZmhrYWxm",
        unit: FastSpeedtest.UNITS.Mbps,
      });
      speedtest
        .getSpeed()
        .then((s) => {
          this.downloadSpeed = Math.floor(s);
          this.downloadFetched = true;
        })
        .catch((e) => {
          console.log(e);
          this.downloadSpeed = 0;
        });
    },
    async checkUploadSpeed() {
      try {
        const pdfEmbed = document.getElementById("pdfEmbed");
        const pdfResponse = await fetch(pdfEmbed.getAttribute("src"));
        const pdfBlob = await pdfResponse.blob();
        const formData = new FormData();
        formData.append("file", pdfBlob);
        const response = await axios.post(
          "https://browsertimer.simplifycv.com/upload/",
          formData
        );
        if (response.status === 200) {
          this.uploadSpeed =
            Math.floor(response.data.uploadSpeedMbps) > 100
              ? 100
              : Math.floor(response.data.uploadSpeedMbps);
          this.uploadFetched = true;
        }
      } catch (error) {
        this.uploadSpeed = 0;
        console.log(error);
      }
    },
  },
};
export default internet;
